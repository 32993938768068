import type { TContactDto } from "~/lib/contactInfo";

export const contactInfo: TContactDto = {
    phone: "+48 530 378 716",
    email: "operations@allmedica.pl",
    address: {
        street: "Aleja Tysiąclecia 111",
        sipCode: "34-400",
        city: "Nowy Targ",
        googleUrl: "https://maps.app.goo.gl/pNADLS5Abxd43QKVA"
    },
    social: [
        {
            link: "https://www.facebook.com/allmedica.surgeryinpoland",
            title: "Follow us on facebook",
            icon: "fb",
            color: "facebook"
        },
        {
            link: "https://www.instagram.com/allmedicasurgeryinpoland",
            title: "Follow us on instagram",
            icon: "instagram",
            color: "instagram"
        },
        {
            link: "https://www.instagram.com/allmedicasurgeryinpoland",
            title: "Subscribe us on YouTube",
            icon: "yt",
            color: "youtube"
        }
    ],
    trustpilot: {
        link: "https://www.trustpilot.com/review/allmedica.com",
        title: "Review us on Trustpilot",
        icon: "trustpilot-logo",
        text: "Review us on"
    }
};
